/* =======================================================================
    Navigation
======================================================================== */

footer {
    padding: 15px 0;

    @include breakpoint(medium) {
        padding: 20px 0;
    }

    .grid-container.fluid {
        padding-left: rem-calc(15px);
        padding-right: rem-calc(15px);

        @include breakpoint(medium) {
            padding-left: rem-calc(30px);
            padding-right: rem-calc(30px);
        }
    }

    .cell {
        position: relative;
    }

    .btt-container {
        margin: 0 auto;
        text-align: center;

        a {
            @include type-h6;

            text-transform: uppercase;
        }
    }

    .copyright {
        position: absolute;
        right: 0;
        bottom: 2px;

        h6 {
            margin: 0;
        }
    }
}
