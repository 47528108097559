/* =======================================================================
    Loading Screen
======================================================================== */

.loading-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: get-color(primary);
    overflow: hidden;
    z-index: 99;

    .loading-position {
        position: relative;
        height: 100vh;
    }

    .loading-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        transform: translate(-50%, -50%);
    }

    .logo-container {
        text-align: center;
    }

    .logo {
        margin-bottom: 15px;

        svg {
            fill: var(--theme-white);
            height: 60px;
            width: 60px;

            @include breakpoint(large) {
                height: 80px;
                width: 80px;
            }
        }
    }

    .name {
        margin-bottom: 45px;

        @include breakpoint(large) {
            margin-bottom: 75px;
        }

        svg {
            fill: var(--theme-white);
            height: 14px;
            width: 105px;

            @include breakpoint(large) {
                height: 17px;
                width: 119px;
            }
        }
    }

    .progress-bar {
        display: block;
        postition: relative;
        width: 90%;
        height: 2px;
        margin: 0 auto;
        border-bottom: 2px solid rgba(255, 255, 242, .2);

        @include breakpoint(medium) {
            width: 75%;
        }

        @include breakpoint(large) {
            width: 50%;
        }

        .bar {
            display: block;
            background: get-color(ternary);
            height: 2px;
            width: 0;
            animation-duration: 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes progress-bar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
