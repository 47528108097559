/* =======================================================================
    Typography
======================================================================== */

@mixin type-h1 {
    font-size: 3.052rem; // 48.8px

    @include breakpoint(large) {
        font-size: calc(16px + 3.875vw); // 90.4px
    }
}

@mixin type-h2 {
    font-size: 2.441rem; // 39.1px

    @include breakpoint(large) {
        font-size: calc(16px + 2.5vw); // 64px
    }
}

@mixin type-h3 {
    font-size: 1.953rem; // 31.3px

    @include breakpoint(large) {
        font-size: calc(16px + 1.52083vw); // 45.2px
    }
}

@mixin type-h4 {
    font-size: 1.563rem; // 25px
    font-family: $body-font-family;
    font-weight:$global-weight-medium;

    @include breakpoint(large) {
        font-size: calc(16px + 0.83333vw); // 32px
    }
}

@mixin type-h5 {
    font-size: 1.25rem; // 20px
    font-family: $body-font-family;
    font-weight:$global-weight-medium;
    text-transform: uppercase;
    letter-spacing: 0.06rem;

    @include breakpoint(large) {
        font-size: calc(16px + .3437vw); // 22.6px
    }
}

@mixin type-h6 {
    font-family: $body-font-family;
    font-size: 1rem; // 16px
    font-weight:$global-weight-medium;

    @include breakpoint(large) {
        font-size: calc(16px + 0.10416vw)
    }
}

@mixin type-paragraph {
    font-size: 1rem; // 16px

    @include breakpoint(large) {
        font-size: calc(16px + 0.10416vw); // 18px
    }
}

@mixin type-label {
    @include type-h5;

    font-weight:$global-weight-normal;
    text-transform: none;
    line-height: 1.7;
    letter-spacing: 0.025rem;
}

@mixin type-link {
    @include type-h5;

    &.active {
        color: get-color(ternary);
    }
}

body,html,h1,h2,h3,h4,h5,h6 {
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
}

h1, .h1 {
    @include type-h1;
}

h2, .h2 {
    @include type-h2;
}

h3, .h3 {
    @include type-h3;
}

h4, .h4 {
    @include type-h4;
}

h5, .h5 {
    @include type-h5;
}

h6, .h6 {
    @include type-h6;
}

p {
    @include type-paragraph;
}

.label {
    @include type-label;
}

a {
    @include type-link;
}
