/* =======================================================================
    Navigation
======================================================================== */

nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    mix-blend-mode: difference;
    pointer-events: none;

    .nav-list {
        &.primary {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            margin: 0;
            padding: 0;

            >.nav-item {
                > .nav-link {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    right: 0;

                    .text-info {
                        position: relative;

                        &::after {
                            content: '';
                            display: inline-block;
                            height: 2px;
                            position: absolute;
                            bottom: -5px;
                            left: 100%;
                            right: 0;
                            background: $medium-gray;
                            transition: left 0.25s ease-out;
                        }
                    }

                    &.active {
                        + .secondary {
                            opacity: 1;
                            visibility: visible;
                        }

                        .text-info::after {
                            left: 0;
                        }
                    }
                }

                &:first-child {
                    > .nav-link {
                        left: 0;
                        right: auto;
                    }
                }
            }
        }

        &.secondary {
            display: flex;
            flex-direction: row;
            position: absolute;
            top: 50px;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.25s ease-in-out;

            @include breakpoint(medium) {
                top: 65px;
                right: 10px;
            }

            .nav-link {
                padding: 20px;
            }
        }
    }

    .nav-link {
        display: block;
        padding: 15px;
        pointer-events: all;

        @include breakpoint(medium) {
            padding: 30px;
        }
    }
}
