/* =======================================================================
    SVG Text
======================================================================== */

.text {
    &-logo {
        svg {
            fill: $medium-gray;
            height: 14px;
            width: 100px;

            @include breakpoint(large) {
                height: 17px;
                width: 119px;
            }
        }
    }

    &-info {
        svg {
            fill: $medium-gray;
            height: 14px;
            width: 47px;

            @include breakpoint(large) {
                height: 17px;
                width: 53px;
            }
        }
    }

    &-email {
        svg {
            fill: $medium-gray;
            height: 14px;
            width: 58px;

            @include breakpoint(large) {
                height: 17px;
                width: 66px;
            }
        }
    }

    &-resume {
        svg {
            fill: $medium-gray;
            height: 14px;
            width: 79px;

            @include breakpoint(large) {
                height: 17px;
                width: 89px;
            }
        }
    }

    &-frontend {
        margin-bottom: 2%;

        svg {
            fill: get-color(primary);
        }
    }

    &-developer {
        svg {
            fill: get-color(primary);
        }
    }

    &-hello {
        position: absolute;
        left: 0;
        width: 111%;
        pointer-events: none;

        @include breakpoint(medium) {
            width: 100%;
        }

        @include breakpoint(large) {
            width: 76%;
        }

        svg {
            fill: get-color(secondary);
            fill-opacity: 0.15;
        }
    }

    &-reachout {
        position: absolute;
        left: 0;
        width: 111%;
        pointer-events: none;

        @include breakpoint(medium) {
            width: 100%;
        }

        @include breakpoint(large) {
            width: 69%;
        }

        svg {
            fill: get-color(secondary);
            fill-opacity: 0.15;
        }
    }
}
