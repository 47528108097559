/* =======================================================================
    Base
======================================================================== */

:root {
  --theme-white: #{$white};
}

.cool {
    --theme-white: #{$cool-white};
}

*, html, body, a, *:hover {
    cursor: none !important;
}

html {
    background: var(--theme-white);

    &.loading,
    &.loading body {
        height: 100%;
        overflow: hidden;
    }
}

body {
    background: var(--theme-white);
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    transition: background 0.5s ease-in;

    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
}
