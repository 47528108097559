/* =======================================================================
    Cursor
======================================================================== */

.cursor {
    pointer-events: none;

    &.hide {
        display: none;
    }

    &-ball,
    &-stroke {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
    }

    &-ball {
        svg {
            fill: get-color('ternary');
        }
    }

    &-stroke {
        svg {
            fill: none;
            stroke: get-color('ternary');
        }
    }
}
