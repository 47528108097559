/* =======================================================================
    layout
======================================================================== */

section {
    position: relative;
    margin-bottom: 10vw;
    min-height: 100vh;
}

.site-container {
    overflow-x: hidden;

    &.fluid {
        padding-left: rem-calc(15px);
        padding-right: rem-calc(15px);

        @include breakpoint(medium) {
            padding-left: rem-calc(30px);
            padding-right: rem-calc(30px);
        }
    }
}

.home {
    &-landing {
        .landing-text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            @include breakpoint(large) {
                width: 90%;
            }
        }

        .landing-nav {
            a {
                opacity: 1;
                transition: opacity 0.5s ease-in;
                position: absolute;
                bottom: 20px;

                &.hide-theme {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        .scroll-down {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 78px;
            right: -45px;
            transform: rotate(90deg);

            @include breakpoint(medium) {
                right: -50px;
            }

            h5 {
                margin: 0;
                padding-right: 20px;
                text-transform: uppercase;
            }

            span {
                line-height: 0;
            };

            svg {
                fill: get-color(primary);
                transform: rotate(-90deg);
            }
        }
    }
    &-info {
        min-height: 600px;

        @include breakpoint(medium) {
            min-height: 100vh;
        }

        .text-hello {
            position: absolute;
            top: 10%;
            left: -20px;

            @include breakpoint(medium) {
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .info-text {
            &-container {
                display: flex;
                position: absolute;
                left: 0;
                right: 0;
                top: 20%;

                @include breakpoint(medium) {
                    top: 50%;
                    transform: translateY(-50%);
                }

                @include breakpoint(large) {
                    justify-content: center;
                    align-items: center;
                    height: 0;
                }
            }

            &-left {
                margin-bottom: 10%;

                @include breakpoint(medium) {
                    margin-bottom: 0;
                }

                @include breakpoint(large) {
                    transform: translateY(-130%);
                }
            }

            &-right {
                @include breakpoint(medium) {
                    flex-direction: row-reverse;
                    transform: translateY(70%);
                }

                @include breakpoint(large) {
                    transform: translateY(55%);
                }
            }
        }

        h3 {
            display: flex;
            flex-direction: column;

            @include breakpoint(medium) {
                white-space: nowrap;
            }

            @include breakpoint(large) {
                justify-content: center;
            }
        }
    }

    &-about {
        .about-intro-container {
            position: relative;
            margin-bottom: 35px;

            @include breakpoint(medium) {
                margin-bottom: 12vw;
            }

            @include breakpoint(large) {
                margin-bottom: 5%;
            }

            img {
                margin-bottom: 10px;
            }
        }

        .about-text-container {
            @include breakpoint(medium) {
                position: absolute;
                top: 10%;
            }

            @include breakpoint(large) {
                top: 15%;
            }

            h2 {
                margin-bottom: 35px;

                @include breakpoint(medium) {
                    margin-bottom: 5%;
                    white-space: nowrap;
                    mix-blend-mode: multiply;
                }

                @include breakpoint(xxlarge) {
                    margin-bottom: 7%;
                }
            }

            p {
                @include breakpoint(medium) {
                    mix-blend-mode: multiply;
                }
            }
        }

        .about-skills-container {
            .cert-text {
                margin-bottom: 35px;

                @include breakpoint(medium) {
                    margin-bottom: 3.25vw;
                }
            }
        }
    }

    &-contact {
        min-height: 600px;

        @include breakpoint(medium) {
            min-height: 100vh;
            margin-bottom: 0;
        }

        .text-reachout {
            position: absolute;
            top: 10%;
            left: -20px;

            @include breakpoint(medium) {
                left: -30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .contact-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 20%;

            @include breakpoint(medium) {
                top: 56%;
                transform: translateY(-50%);
            }
        }

        .contact-text-right {
            position: relative;
            top: 7vw;
            text-align: right;

            @include breakpoint(medium) {
                top: 4vw;
            }

            @include breakpoint(large) {
                position: static;
            }
        }

        .contact-email {
            margin-bottom: 10px;

            a {
                @include type-h1;

                font-family: $header-font-family;
                position: relative;
                text-transform: none;

                @include breakpoint(small only) {
                    font-size: 13vw;
                }

                &:hover {
                    &::after {
                        left: 100%;
                    }
                    &::before {
                        transition-delay: .50s;
                        right: 0;
                    }
                }

                &::after {
                    content: '';
                    display: inline-block;
                    height: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: $black;
                    transition: left 0.25s ease-out;
                }

                &::before {
                    content: '';
                    display: inline-block;
                    height: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 100%;
                    background: $black;
                    transition: right 0.25s ease-out;
                }
            }
        }

        .contact-resume {
            a {
                @include type-h4;

                text-transform: uppercase;
            }
        }

        .contact-list-links {
            position: relative;
            top: 20vw;

            @include breakpoint(medium) {
                top: 10vw;
            }
        }

        .contact-list {
            @include breakpoint(medium) {
                text-align: right;
            }

            .contact-item {
                padding: 5px;

                @include breakpoint(medium) {
                    display: inline-block;
                    padding: 25px;
                }

                &:last-child {
                    padding-right: 0;
                }

                a {
                    @include type-h5;

                    text-transform: uppercase;
                }
            }
        }
    }
}
